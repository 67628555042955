import App from './views/App.vue';
import router from './routes';

let app = new Vue({
        data() { 
            return {
                globalMessages: []
            }
        },
        router,
        render: h => h(App),
}).$mount('#app')

AppKit.init(app);