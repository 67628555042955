<template>
<!--    <v-app class="ak-page-wrap">-->
<!--        &lt;!&ndash; @todo add check if user is authenticated if so shaw ak-navigation&ndash;&gt;-->
<!--        <header class="ak-head">-->
<!--            <ak-navigation-->
<!--                    :logoPath="$getSetting('assets.logo')"-->
<!--                    :notifications="0"-->
<!--                    @toggle-drawer="showDrawer = ! showDrawer"-->
<!--            />-->
<!--            <v-progress-linear-->
<!--                    v-if="loadingState"-->
<!--                    class="ak-loader"-->
<!--                    indeterminate-->
<!--                    fixed-->
<!--                    background-color="null"-->
<!--                    color="accent"-->
<!--                    height="4"-->
<!--            ></v-progress-linear>-->
<!--        </header>-->
<!--        <ak-drawer-->
<!--                :logoPath="$getSetting('assets.logo')"-->
<!--                :showDrawer="showDrawer"-->
<!--                @close="showDrawer = false"-->
<!--        >-->
<!--            <ak-menu>-->
<!--&lt;!&ndash;                <ak-menu-item&ndash;&gt;-->
<!--&lt;!&ndash;                        title="Teksten"&ndash;&gt;-->
<!--&lt;!&ndash;                        href="/grid/text"&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                </ak-menu-item>&ndash;&gt;-->
<!--            </ak-menu>-->
<!--        </ak-drawer>-->

<!--        <div class="ak-main">-->
<!--            <router-view></router-view>-->
<!--        </div>-->

<!--    </v-app>-->
    <div class="ak-wrapper">
        <ak-header
            v-model="showDrawer"
            :drawerPinned="drawerPinned"
        />
        <ak-drawer
            :logoPath="$getSetting('assets.logoDrawer')"
            :showDrawer="showDrawer"
            @close="showDrawer = false"
            @togglePinned="drawerPinned = $event"
        >
            <ak-menu v-if="menu"
                :items="menu"
                :searchEnabled="true"
                 @menuItemClicked="showDrawer = false"
            >
            </ak-menu>
        </ak-drawer>

        <div class="ak-content"
            :class="{'ak-content--pinned': drawerPinned}"
        >
            <router-view></router-view>
        </div>
        <ak-errors />
        <ak-snackbar
            v-for="(message, i) in $root.globalMessages"
            :key="i"
            v-model="message.text"
            @remove="$root.globalMessages.splice(i, 1)"
        />
    </div>


</template>

<script>
    export default {
        name: 'app',
        data() {
            return {
                loadingState: false,
                showDrawer: false,
                drawerPinned: false,
                menu: [],
            }
        },
        created() {
            this.menu = menu;
        }
    }
</script>

