var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("ak-data-table", {
        ref: _vm.bundle,
        attrs: {
          bundle: _vm.bundle,
          definition: _vm.gridDefinition,
          infiniteScroll: false,
        },
        on: { edit: _vm.goToForm, delete: _vm.goToGrid, new: _vm.goToForm },
      }),
      _vm._v(" "),
      _c("router-view", { attrs: { name: "childview" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }