import VueRouter from 'vue-router';
import Grid from './views/pages/Grid.vue';
import Form from './views/pages/Form.vue';
import PageNotFound from './views/pages/PageNotFound.vue';
import Dashboard from './views/pages/Dashboard.vue';
import UiKit from './views/pages/UiKit.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Dashboard,
        meta: {
            title: "dashboard",
        },
        alias: '/home'
    },
    {
        path: '/ui-kit',
        component: UiKit,
        meta: {
            title: "UI kit",
        },
    },
    ...Vue.blueprintRoutes,
    {
        path: '/:gridDefinition/:bundle',
        name: 'grid',
        component: Grid,
        meta: {
            title: "Grid"
        },
        children: [
			{
				path: ':definition/:objectId/:relationString?',
				name: 'form',
				components:{
					childview: Form
				},
			}
		]
    },
    {
        path: "*",
        component: PageNotFound
    }
];



const router = new VueRouter({
    routes
})

export default router;