var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ak-stacked-window",
        {
          attrs: { showClose: false },
          on: {
            attemptClose: function ($event) {
              return _vm.attemptClose($event)
            },
            close: _vm.close,
          },
        },
        [
          _c("ak-form", {
            attrs: {
              bundle: _vm.bundle,
              definition: _vm.definition,
              objectId: _vm.objectId,
              relationString: _vm.relationString,
            },
            on: {
              alertUnsavedChanges: _vm.alertUnsavedChanges,
              save: _vm.goToGrid,
              cancel: function ($event) {
                return _vm.attemptClose(_vm.close)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ak-modal",
        {
          attrs: {
            confirmText: _vm.$t("Accepteren"),
            cancelText: _vm.$t("Annuleren"),
          },
          on: {
            onConfirm: _vm.confirmDialog,
            onCancel: function ($event) {
              _vm.showConfirm = false
            },
          },
          model: {
            value: _vm.showConfirm,
            callback: function ($$v) {
              _vm.showConfirm = $$v
            },
            expression: "showConfirm",
          },
        },
        [
          _c(
            "h1",
            { directives: [{ name: "translate", rawName: "v-translate" }] },
            [_vm._v("Opgepast!")]
          ),
          _vm._v(" "),
          _vm.unsavedChangesMessage
            ? _c(
                "span",
                { directives: [{ name: "translate", rawName: "v-translate" }] },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.unsavedChangesMessage) +
                      "\n        "
                  ),
                ]
              )
            : _c(
                "span",
                { directives: [{ name: "translate", rawName: "v-translate" }] },
                [
                  _vm._v(
                    "\n            Ben je zeker dat je dit invulformulier wilt sluiten zonder te bewaren? De wijzigingen die je hebt gedaan, zullen verloren gaan.\n        "
                  ),
                ]
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }