var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "bg--white border-radius m-t-25 m-b-25 p-30" }, [
      _c("h1", [_vm._v("\n            Dashboard\n        ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ak-button-group" },
        [
          _c("ak-button", { attrs: { href: "/cms#/ui-kit" } }, [
            _c("i", { staticClass: "ak-icon ak-button__icon" }, [
              _vm._v("grid_view"),
            ]),
            _vm._v(" UI kit\n            "),
          ]),
          _vm._v(" "),
          _c("ak-button", { attrs: { href: "/cms#/blueprints" } }, [
            _c("i", { staticClass: "ak-icon ak-button__icon" }, [
              _vm._v("edit"),
            ]),
            _vm._v(" Blueprints\n            "),
          ]),
          _vm._v(" "),
          _c("ak-button", { attrs: { href: "/cms#/grid/text" } }, [
            _c("i", { staticClass: "ak-icon ak-button__icon" }, [
              _vm._v("text_fields"),
            ]),
            _vm._v(" Text\n            "),
          ]),
          _vm._v(" "),
          _c("ak-button", { attrs: { href: "/cms#/grid/article" } }, [
            _c("i", { staticClass: "ak-icon ak-button__icon" }, [
              _vm._v("article"),
            ]),
            _vm._v(" Articles\n            "),
          ]),
          _vm._v(" "),
          _c("ak-button", { attrs: { href: "/cms#/grid/recipe" } }, [
            _c("i", { staticClass: "ak-icon ak-button__icon" }, [
              _vm._v("restaurant"),
            ]),
            _vm._v(" Recipies\n            "),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }