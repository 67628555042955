<template>
    <div class="container-fluid">
        <!-- TABLE -->
        <ak-data-table
                :bundle="bundle"
                :definition="gridDefinition"
                :infiniteScroll="false"
                :ref="bundle"
                @edit="goToForm"
                @delete="goToGrid"
                @new="goToForm"
        />

        <router-view name="childview"></router-view>
    </div>
</template>

<script>
    export default {
        name: 'Grid',
        computed: {
            bundle() {
                return this.$route.params.bundle;
            },
            gridDefinition() {
                return this.$route.params.gridDefinition;
            }
        },
        methods: {
            goToForm(data) {
                this.$router.push({ name: 'form' , params: {
                    bundle: data.bundleId,
                    definition: data.definitionId,
                    objectId: data.objectId,
                }});
            },
            goToGrid(data) {
                this.$router.push({ name: 'grid' , params: {
                    bundle: data.bundleId,
                    gridDefinition: data.definitionId,
                }});
            }
        }
    }
</script>
