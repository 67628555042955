import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=9c9afbc4&"
import script from "./Form.vue?vue&type=script&lang=js&"
export * from "./Form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/htdocs/blueprints-test/src/vue-apps/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9c9afbc4')) {
      api.createRecord('9c9afbc4', component.options)
    } else {
      api.reload('9c9afbc4', component.options)
    }
    module.hot.accept("./Form.vue?vue&type=template&id=9c9afbc4&", function () {
      api.rerender('9c9afbc4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "views/pages/Form.vue"
export default component.exports