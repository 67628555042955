<template>
    <div>
        <ak-stacked-window
                :showClose="false"
                @attemptClose="attemptClose($event)"
                @close="close"
        >
            <ak-form
                :bundle="bundle"
                :definition="definition"
                :objectId="objectId"
                :relationString="relationString"
                @alertUnsavedChanges="alertUnsavedChanges"
                @save="goToGrid"
                @cancel="attemptClose(close)"
            >

            </ak-form>
        </ak-stacked-window>

        <ak-modal
            v-model="showConfirm"
            :confirmText="$t('Accepteren')"
            :cancelText="$t('Annuleren')"
            @onConfirm="confirmDialog"
            @onCancel="showConfirm = false"
        >
            <h1 v-translate>Opgepast!</h1>
            <span
                    v-if="unsavedChangesMessage"
                    v-translate
            >
                {{unsavedChangesMessage}}
            </span>
            <span
                    v-else
                    v-translate
            >
                Ben je zeker dat je dit invulformulier wilt sluiten zonder te bewaren? De wijzigingen die je hebt gedaan, zullen verloren gaan.
            </span>
        </ak-modal>
    </div>
</template>

<script>

    export default {
        name: 'Form',
        data() {
            return {
                showConfirm: false,
                confirmCallback: null,
                alertUnsaved: null,
                unsavedChangesMessage: false,
            }
        },
        computed: {
            bundle() {
                return this.$route.params.bundle;
            },
            definition() {
                return this.$route.params.definition;
            },
            objectId() {
                return this.$route.params.objectId;
            },
            relationString() {
                return this.$route.params.relationString;
            }
        },
        methods: {
            goToGrid(data) {
                if(this.$route.name === 'blueprint-form') {
                    this.$router.push({ name: 'blueprint'});
                    this.$emit('save', data);
                    return;
                }

                this.$router.push({ name: 'grid' , params: {
                        bundle: data.bundleId,
                        gridDefinition: data.definitionId,
                }});
            },
            confirmDialog() {
                this.showConfirm = false;
                this.confirmCallback();
            },
            alertUnsavedChanges({showAlert, message}) {
                this.alertUnsaved = showAlert;
                this.unsavedChangesMessage = message;
            },
            close() {
                this.$router.back();
            },
            attemptClose(close) {
                if (! this.alertUnsaved) {
                    close();
                }

                this.showConfirm = true;
                this.confirmCallback = close;
            }
        }
    }
</script>
