<template>
    <div class="container">
        <div class="bg--white border-radius m-t-25 m-b-25 p-30">
            <h1>
                Dashboard
            </h1>
            <div class="ak-button-group">
                <ak-button
                    href="/cms#/ui-kit"
                >
                    <i class="ak-icon ak-button__icon">grid_view</i> UI kit
                </ak-button>
                <ak-button
                    href="/cms#/blueprints"
                >
                    <i class="ak-icon ak-button__icon">edit</i> Blueprints
                </ak-button>
                <ak-button
                    href="/cms#/grid/text"
                >
                    <i class="ak-icon ak-button__icon">text_fields</i> Text
                </ak-button>
                <ak-button
                    href="/cms#/grid/article"
                >
                    <i class="ak-icon ak-button__icon">article</i> Articles
                </ak-button>
                <ak-button
                    href="/cms#/grid/recipe"
                >
                    <i class="ak-icon ak-button__icon">restaurant</i> Recipies
                </ak-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Dashboard',
    }
</script>
