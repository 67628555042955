var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ak-wrapper" },
    [
      _c("ak-header", {
        attrs: { drawerPinned: _vm.drawerPinned },
        model: {
          value: _vm.showDrawer,
          callback: function ($$v) {
            _vm.showDrawer = $$v
          },
          expression: "showDrawer",
        },
      }),
      _vm._v(" "),
      _c(
        "ak-drawer",
        {
          attrs: {
            logoPath: _vm.$getSetting("assets.logoDrawer"),
            showDrawer: _vm.showDrawer,
          },
          on: {
            close: function ($event) {
              _vm.showDrawer = false
            },
            togglePinned: function ($event) {
              _vm.drawerPinned = $event
            },
          },
        },
        [
          _vm.menu
            ? _c("ak-menu", {
                attrs: { items: _vm.menu, searchEnabled: true },
                on: {
                  menuItemClicked: function ($event) {
                    _vm.showDrawer = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ak-content",
          class: { "ak-content--pinned": _vm.drawerPinned },
        },
        [_c("router-view")],
        1
      ),
      _vm._v(" "),
      _c("ak-errors"),
      _vm._v(" "),
      _vm._l(_vm.$root.globalMessages, function (message, i) {
        return _c("ak-snackbar", {
          key: i,
          on: {
            remove: function ($event) {
              return _vm.$root.globalMessages.splice(i, 1)
            },
          },
          model: {
            value: message.text,
            callback: function ($$v) {
              _vm.$set(message, "text", $$v)
            },
            expression: "message.text",
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }