var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "p-t-50 p-b-50" },
      [
        _c("h1", [_vm._v(_vm._s(_vm.$t("Pagina niet gevonden")))]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            _vm._s(
              _vm.$t(
                "Deze pagina bestaat niet, of heeft nooit bestaan. Onze excuses voor het ongemak."
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c("ak-button", { attrs: { href: "/cms#/" } }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("Keer terug naar de homepage")) +
              "\n        "
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }